<main>
    <img src="/assets/logo.png" alt="Brewscout Logo" aria-label="Brewscout Logo" />
</main>

<style>
    html,
    body {
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 100%;
    }
    main {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
    }
    img {
        max-width: 500px;
        max-height: 100%;
    }
</style>
